import { InjectionToken } from '@angular/core';
import { SvgIconIdentifier } from '@bcfds-design-system/svg-icon/types';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SVG_ICON_HOST: InjectionToken<string> = new InjectionToken<string>('svgIconhost', {
  factory: () => 'https://image-assets-server-dot-cms-headless-gae.ew.r.appspot.com'
});
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SVG_ICON_IDENTIFIER: InjectionToken<SvgIconIdentifier> = new InjectionToken<SvgIconIdentifier>(
  'SvgIconIdentifier'
);
