import { DOCUMENT, isPlatformServer } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Inject, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { SvgIconAppComputedStylesService } from '@bcfds-design-system/svg-icon/svg-icon-app-computed-styles.service';
import { SVG_ICON_HOST, SVG_ICON_IDENTIFIER } from './token';
import { SvgIconIdentifier } from './types';

export function provideSvgIcons(svgIconsIdentifier: SvgIconIdentifier, svgIconHost?: string): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory:
        (svgIconAppComputedStyles: SvgIconAppComputedStylesService, document: Document, platformId: string) =>
        async () => {
          if (isPlatformServer(platformId)) {
            return;
          }
          svgIconAppComputedStyles.setComputedStyles(getComputedStyle(document.body));
        },
      deps: [SvgIconAppComputedStylesService, [new Inject(DOCUMENT)], [new Inject(PLATFORM_ID)]],
      multi: true
    },
    {
      provide: SVG_ICON_IDENTIFIER,
      useValue: svgIconsIdentifier
    },
    {
      provide: SVG_ICON_HOST,
      useValue: svgIconHost ?? 'https://image-assets-server-dot-cms-headless-gae.ew.r.appspot.com'
    }
  ]);
}
