export function getValueFromComputedStyles(
  computedStyles: CSSStyleDeclaration | undefined,
  cssProperty: string | undefined
): string | undefined {
  if (!computedStyles || !cssProperty) {
    return undefined;
  }
  const result: string = computedStyles.getPropertyValue(cssProperty);
  if (result.length === 0) {
    return undefined;
  }
  return result;
}
