import { Injectable } from '@angular/core';
import { Color } from '@bcfds-design-system/svg-icon/types';
import { getValueFromComputedStyles } from './utils/get-value-from-computed-styles';

@Injectable({ providedIn: 'root' })
export class SvgIconAppComputedStylesService {
  private _computedStyles: CSSStyleDeclaration | undefined;

  public setComputedStyles(computedStyles: CSSStyleDeclaration): void {
    this._computedStyles = computedStyles;
  }

  public getCssPropertyValue(cssProperty: Color | undefined): string | undefined {
    if (!this._computedStyles) {
      return undefined;
    }
    return getValueFromComputedStyles(this._computedStyles, cssProperty);
  }
}
