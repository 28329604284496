import { BcfAppId, BcfReleaseVersion } from '@bcf-v2-configurators/tokens/app/types';
import { ErrorCandidate } from './extractors';
import { ErrorPrepared, getErrorPrepared } from './get-error-prepared';

function stringifyPayload(bodyPayload: Record<string, any>): string {
  try {
    return JSON.stringify(bodyPayload);
  } catch (err) {
    const newPayload: Record<string, any> = {
      ...bodyPayload,
      rawError: undefined
    };
    return JSON.stringify(newPayload);
  }
}

async function sendError(
  bcfAppId: BcfAppId,
  bcfReleaseVersion: BcfReleaseVersion,
  preparedError: ErrorPrepared | ErrorCandidate,
  url: string | undefined,
  cookies: Record<string, string>
): Promise<void> {
  // if (rawError instanceof HttpErrorResponse && [401, 404, 403, 0, 504].includes(rawError.status)) {
  //   return;
  // }
  const bodyPayload: Record<string, any> = {
    appId: bcfAppId,
    version: bcfReleaseVersion,
    url: url,
    error: preparedError,
    cookies: cookies
  };

  try {
    const crashUrl: string = `https://crash-logger-dot-cms-headless-gae.ew.r.appspot.com/error-reporting/`;
    // const crashUrl: string = `http://localhost:3005/error-reporting/`;
    await fetch(crashUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: stringifyPayload(bodyPayload)
    });
  } catch (err) {}
  return undefined;
}

export async function sendToErrorReporting(
  bcfAppId: BcfAppId,
  bcfReleaseVersion: BcfReleaseVersion,
  error: Error | undefined,
  url: string | undefined,
  cookies: Record<string, string>
): Promise<void> {
  try {
    const preparedError: ErrorPrepared | undefined = getErrorPrepared(error);
    if (!preparedError) {
      return;
    }

    if (error?.stack?.includes('googletagmanager')) {
      return;
    }
    if (error?.stack?.includes('rezync.com')) {
      return;
    }

    sendError(bcfAppId, bcfReleaseVersion, preparedError, url, cookies);
  } catch (err) {}
}

export async function sendToErrorUnhandledReporting(
  bcfAppId: BcfAppId,
  bcfReleaseVersion: BcfReleaseVersion,
  preparedError: ErrorCandidate | undefined,
  url: string | undefined,
  cookies: Record<string, string>
): Promise<void> {
  try {
    if (!preparedError) {
      return;
    }

    sendError(bcfAppId, bcfReleaseVersion, preparedError, url, cookies);
  } catch (err) {}
}
