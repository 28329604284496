import { HttpErrorResponse } from '@angular/common/http';

function extractHostname(error: HttpErrorResponse): string | undefined {
  if (!error.url) {
    return undefined;
  }
  const url: URL = new URL(error.url);
  return url.hostname;
}

export class HttpCustomError implements Error {
  constructor(
    public name: string,
    public message: string,
    public url: string | undefined,
    public statusCode: number,
    public errorContent?: unknown
  ) {}

  public static fromHttpErrorResponse(error: HttpErrorResponse): HttpCustomError {
    const hostname: string | undefined = extractHostname(error);
    return new HttpCustomError(
      'HttpError',
      hostname ? `Http error: (https://${hostname}) with code: (${error.status})` : error.message,
      error.url ?? undefined,
      error.status,
      error.error
    );
  }
}
